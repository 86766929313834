import * as React from "react";
import image from '../../images/bruce.png';
import image2 from '../../images/bruce2.png';
import image3 from '../../images/bruce3.png';

import Project from '../../Layouts/project';

const { Fragment } = React;

const Bruce = () => {
  return (
    <Project 
      title="Bruce's America" 
      images={[image2, image3, image]}
      description={<Fragment>A side project to create sketchy style maps of the places mentioned in Bruce Springsteen songs, using <a href="https://roughjs.com/" target="blank">rough.js</a> and <a href="https://www.projectlinework.org/" target="_blank">Project Linework</a>. 
      Built with D3 and Svelte, live site still under construction. I used Python and Spacy to parse out locations from the lyrics</Fragment>} 
      />)
}

export default Bruce
